<template>
  <div style="background-color: #faf9f9">
    <Myhead :nav="7"></Myhead>
    <div class="header">
      <div class="content1">
        <div class="content1-title">{{ Language.contactUs.contactUs }}</div>
        <div class="form">
          <div class="label">{{ Language.contactUs.name }}</div>
          <input v-model="user_name" :placeholder="Language.contactUs.Enter_Name">
        </div>
        <div class="form">
          <div class="label">{{ Language.contactUs.E_mail }}</div>
          <input v-model="email" :placeholder="Language.contactUs.Enter_E_mail">
        </div>
        <div class="form">
          <div class="label">{{ Language.contactUs.Telephone }}</div>
          <input v-model="mobile" :placeholder="Language.contactUs.Enter_Telephone">
        </div>
        <div class="form">
          <div class="label">{{ Language.contactUs.company }}</div>
          <input v-model="company_name" :placeholder="Language.contactUs.Enter_company">
        </div>
        <div class="form">
          <div class="label">{{ Language.contactUs.question }}</div>
          <textarea v-model="problem" :placeholder="Language.contactUs.Enter_question"></textarea>
        </div>
        <div>
          <div class="btn1" @click="formsubmit">{{ Language.contactUs.submit }}</div>
        </div>
      </div>
    </div>
    <div style="background-color: #ffffff;">
      <div class="content2" style="display: flex;justify-content: center;align-items: center">
        <div class="map" style="flex-shrink: 0">
          <img class="imgStyle" src="../assets/img/contactUs/map.png" alt/>
          <div class="nanjing-point" v-for="(item, index) in mapdata"
               :style="'left:' + (item.x) + 'px;top:' + (item.y) + 'px' " @mouseover="showabs(index)"
               @mouseleave="noshow()">
            <div class="pointrelative" v-if="item.show">
              <div class="pointabs">
                <span v-if="Language_type == 1">{{ item.name }}</span>
                <span v-if="Language_type != 1">{{ item.ename }}</span>
              </div>
            </div>
          </div>
          <!--          <img class="nanjing" src="../assets/img/contactUs/NanJing.png" alt/>-->
          <!--          <div class="HongKong-point"></div>-->
          <!--          <img class="HongKong" src="../assets/img/contactUs/HongKong.png" alt/>-->
        </div>

      </div>

      <div class="content2">
        <div class="info">
          <div>
            <div class="company">{{ Language.contactUs.company2 }}</div>
            <div class="content">
              <div class="item">
                <div class="item-title">{{ Language.contactUs.mail }}</div>
                <div class="item-text">{{ Language.contactUs.mail_address }}</div>

              </div>
              <div class="item">
                <div class="item-title">{{ Language.contactUs.phone }}</div>
                <div class="item-text">{{ Language.contactUs.telephone1 }}</div>
                <div class="item-text">{{ Language.contactUs.telephone2 }}</div>
                <div class="item-text">{{ Language.contactUs.WhatsApp }}</div>
              </div>
              <div class="item">
                <div class="item-title">{{ Language.contactUs.address }}</div>
                <div class="item-text">{{ Language.contactUs.address1 }}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="qrcode">
              <img class="imgStyle" src="../assets/img/contactUs/qrcode.jpg" alt/>
            </div>
            <div class="qrcode-text">{{ Language.contactUs.message1 }}</div>
          </div>
        </div>
      </div>
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myheadWhite";
import Myfoot from "@/component/myfoot";

export default {
  inject: ['reload', 'L'],
  computed: {
    Language() {
      return this.L()
    }
  },
  components: {
    Myhead,
    Myfoot
  },
  data() {
    return {
      Language_type: "",

      user_name: "",
      email: "",
      mobile: "",
      problem: "",
      company_name: "",

      mapdata: [
        {
          name: '中国 山东',
          ename: 'Shandong',
          x: 996,
          y: 192,
          show: false
        },
        {
          name: '中国 安徽',
          ename: 'Anhui',
          x: 983,
          y: 220,
          show: false
        },
        {
          name: '中国 江苏',
          ename: 'Jiangsu',
          x: 996,
          y: 206,
          show: false
        },
        {
          name: '中国 浙江',
          ename: 'Zhejiang',
          x: 996,
          y: 220,
          show: false
        },
        {
          name: '中国 福建',
          ename: 'Fujian',
          x: 983,
          y: 247,
          show: false
        },
        {
          name: '中国 广东',
          ename: 'Guangdong',
          x: 970,
          y: 261,
          show: false
        },
        {
          name: '中国 广西',
          ename: 'Guangxi',
          x: 945,
          y: 261,
          show: false
        },
        {
          name: '中国 湖南',
          ename: 'Hunan',
          x: 957,
          y: 233,
          show: false
        },
        {
          name: '中国 台湾',
          ename: 'Taiwan',
          x: 1002,
          y: 255,
          show: false
        },
        {
          name: '马来西亚',
          ename: 'Malaysia',
          x: 932,
          y: 357,
          show: false
        },
        {
          name: '印尼',
          ename: 'Indonesia',
          x: 984,
          y: 384,
          show: false
        },
        {
          name: '越南',
          ename: 'Vietnam',
          x: 919,
          y: 288,
          show: false
        },
        {
          name: '泰国',
          ename: 'Thailand',
          x: 919,
          y: 316,
          show: false
        },
        {
          name: '韩国',
          ename: 'Korea',
          x: 1017,
          y: 192,
          show: false
        },
      ]
    }
  },
  activated() {
  },
  mounted() {
    this.Language_type = localStorage.getItem("Language_type")
  },
  methods: {
    formsubmit() {
      var data = {
        user_name: this.user_name,
        email: this.email,
        mobile: this.mobile,
        problem: this.problem,
        company_name: this.company_name,
      }
      console.log(data)
      if (!data.email && !data.mobile) {
        this.$toast("请输入邮箱地址或者联系电话")
        return
      }
      if (!data.user_name) {
        this.$toast("请输入姓名")
        return
      }
      if (!data.company_name) {
        this.$toast("请输入公司名称")
        return
      }
      if (!data.problem) {
        this.$toast("请输入你的问题")
        return
      }
      this.$post("/m/indexset/link_record", data).then(res => {
        console.log(res)
        if (res.data.status == 0) {
          this.$toast(res.data.msg)
        } else {
          this.$toast("提交成功 请耐性等待致电~")
          this.user_name = ""
          this.email = ""
          this.mobile = ""
          this.problem = ""
          this.company_name = ""
        }
      })
    },
    showabs(index) {
      this.mapdata[index].show = true
    },
    noshow() {
      this.mapdata.forEach(item => {
        item.show = false
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.header {
  background-image: url("../assets/img/contactUs/bg2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.content1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 130px;

  .content1-title {
    color: var(--title-color);
    font-size: 42px;
    text-align: center;
  }

  .form {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .label {
      width: 64px;
      height: 56px;
      color: var(--title-color);
      text-align: right;
      //line-height: 56px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    input {
      flex: 1;
      height: 56px;
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding-left: 16px;
      font-size: 18px;
      color: var(--title-color);
    }

    input::placeholder {
      font-size: 14px;
      color: #999999;
    }

    input:focus {
      outline: 1px solid var(--theme-color1);
      border-color: var(--theme-color1);
    }

    textarea {
      flex: 1;
      height: 160px;
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 16px;
      font-size: 18px;
      color: var(--title-color);
    }

    textarea::placeholder {
      font-size: 14px;
      color: #999999;
    }

    textarea:focus {
      outline: 1px solid var(--theme-color1);
      border-color: var(--theme-color1);
    }
  }

  .btn1 {
    margin: 120px auto 0;
  }
}

.content2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 0;

  .map {
    width: 1200px;
    height: 642px;
    position: relative;
    z-index: 1;

    .nanjing-point {
      width: 10px;
      height: 10px;
      background-color: #f5746b;
      //background-color: #1989fa;
      border-radius: 10px;
      position: absolute;
      top: 228px;
      left: 1026px;

      .pointrelative {
        position: relative;

        .pointabs {
          left: -45px;
          top: -45px;
          color: #FFFFFF;
          text-align: center;
          position: absolute;
          width: 100px;
          line-height: 30px;
          height: 40px;
          background-image: url("../assets/img/contactUs/qipao.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          z-index: 2;
        }
      }
    }

    .nanjing {
      width: 93px;
      height: 38px;
      position: absolute;
      top: 186px;
      left: 984px;
      z-index: 2;
    }

    .HongKong-point {
      width: 10px;
      height: 10px;
      background-color: #f5746b;
      border-radius: 10px;
      position: absolute;
      top: 290px;
      left: 1016px;
      z-index: 2;
    }

    .HongKong {
      width: 93px;
      height: 38px;
      position: absolute;
      top: 248px;
      left: 974px;
      z-index: 2;
    }
  }


  .info {
    margin-top: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .company {
      color: var(--title-color);
      font-size: 30px;
      margin-bottom: 40px;
    }

    .content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .item {
        margin-right: 30px;
        width: 278px;

        .item-title {
          color: var(--title-color);
          font-size: 20px;
          font-weight: bold;
          text-align: justify;
          padding-bottom: 16px;
          border-bottom: 1px solid var(--theme-color1);
        }

        .item-text {
          color: var(--text-color);
          font-size: 14px;
          line-height: 22px;
          text-align: justify;
          margin-top: 16px;
        }
      }
    }

    .qrcode {
      width: 160px;
      height: 160px;
    }

    .qrcode-text {
      font-size: 14px;
      color: var(--text-color);
      line-height: 22px;
      text-align: center;
    }
  }
}


@media (min-width: 1100px) and (max-width: 1300px) {
  .map {
    transform: scale(0.8);
  }
  .content1 {
    padding: 20px;
  }
  .content2 {
    width: 100%;
  }
}

@media (min-width: 800px) and (max-width: 1100px){
  .map {
    transform: scale(0.6);
  }
}
@media (min-width: 500px) and (max-width: 800px){
  .content2{
    padding: 0px ;
  }
  .map {
    transform: scale(0.4);
  }
}

@media (min-width: 0px) and (max-width: 500px){
  .content2{
    padding: 0px ;
    //height: 500px;
  }
  .map {
    transform: scale(0.3);
  }
}

@media (min-width: 0px) and (max-width: 1100px) {
  .content1 {
    padding: 20px;
  }
  .content2 {
    width: 100%;
    .info{
      flex-wrap: wrap;
      padding: 0px 20px;
      .content{
        flex-wrap: wrap;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 964px){
  .content2{
    .info{
      display: block;
      .content{
        width: 100%;
        .item{
          width: 50%;
          margin-right: 0;
        }
      }
      .qrcode-text{
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 600px){
  .content2{
    .info{
      display: block;
      .content{
        width: 100%;
        .item{
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 1920px) {
  .header{
    background-size: auto 100%;
  }
}
</style>
